/**
 * @author Jason Curren
 * @description Globally available mixin for Google Analytics and knowing if accepted or not
 * ================================================================================================
 */

export const googleAnalytics = {
    mounted() {
        this.checkGoogleAnal();
    },
    methods: {
        checkGoogleAnal() {
            const cookie = localStorage.getItem("site-cookies");

            const value = cookie ? cookie : "not-clicked";

            this.$store.commit("cookies/TOGGLE_COOKIES", value);
        }
    }
}