export default function ({ app, req, route }) {
    const subdomain = req.headers.host.split('.')[0]; 
    console.log(subdomain);
    
    // Get the locale from the query parameter
    let locale = route.query.lang;
  
    // Fallback to default locale if not provided
    if (!locale) {
      // Get locale from cookie if available
      if (req) {
        const cookie = req.headers.cookie && req.headers.cookie.split(';').find(c => c.trim().startsWith('i18n_redirected='));
        if (cookie) {
          locale = cookie.split('=')[1];
        }
      } else {
        // Check for locale in the client-side cookies
        locale = app.$cookies.get('i18n_redirected');
      }
      
      // Fallback to default locale if no locale found
      if (!locale) {
        locale = app.i18n.defaultLocale;
      }
    }
  
    // Set the locale
    app.i18n.setLocale(locale);
  }
  