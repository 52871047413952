/**
 * @author Jason Curren
 * @description inject papaparse to the app, global scope as $csv
 * ================================================================================================
 */

import papaparse from "papaparse";

export default ((app, inject) => {
    inject("papa", papaparse);
});