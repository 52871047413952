
    export default {
        name: "IconLoader",
        props: {
            fill: {
                default: "var(--color-primary)",
                type: String
            },
            text: {
                default: true,
                type: Boolean
            }
        }
    }
