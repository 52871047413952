
    /**
     * @author Jason Curren
     * @description Reusable select field component
     * ============================================================================================
     */
    import { formProps } from "@/mixins/form/formProps";

    // ============================================================================================
    export default {
        mixins: [formProps],
        name: "FormSelect",
        props: {
            className: {
                default: "form-select",
                type: String
            },
            translateOptions: {
                default: true,
                type: Boolean
            }
        }
    }
