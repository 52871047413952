/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const state = () => ({
    roles: {
        admin: "Administrator",
        account_manager: "Account Manager",
        customer_service: "Customer Service",
    }
});