
    export default {
        name: "IconClose",
        props: {
            fill: {
                default: "#FFF",
                type: String
            }
        }
    }
