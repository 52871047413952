
    /**
     * @description Popup modal to show store basket and checkout
     */
    export default {
        name: "ShoppingBasket",
        data() {
            return {
                quantities: [],
                checkoutStage: true,
            }
        },
        mounted() {
            this.$store.state.basket.products.forEach(data => {
                this.quantities.push(data.quantity);
            });
        },
        computed: {
            totalPrice() {
                let output = 0;

                this.$store.state.basket.products.forEach(data => {
                    output += parseInt(data.quantity) * data.price;
                });

                return output.toFixed(2);
            },
            canCheckout() {
               return this.$store.state.basket.products.length > 0;
            }
        },
        methods: {
            checkoutBasket () {
                if (this.canCheckout) {
                    this.checkoutStage = true;
                }
            },
            addQuantity(index) {
                this.quantities[index]++;

                this.$store.commit("basket/ADD_QUANTITY", index);
            },
            removeQuantity(index) {
                if (this.quantities[index]) {
                    this.quantities[index]--;

                    this.$store.commit("basket/REMOVE_QUANTITY", index);
                }
            },
        }
    }
