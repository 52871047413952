/**
 * @author Jason Curren
 * @description Global store for products
 * ================================================================================================
 */
export const state = () => ({
    product: {
        products: {}
    },
    products: [],
    refresh: 0,
    customTextConsumerFrontPreview: null,
    customTextConsumerBackPreview: null
});

export const mutations = {
    UPDATE_PRODUCT(state, value) {
        state.product = value;
    },

    UPDATE_PRODUCTS(state, value) {
        state.products = value;
    },

    UPDATE_PRODUCT_REFRESH(state) {
        state.refresh++;
    },

    UPDATE_CUSTOM_TEXT_FRONT_PREVIEW_IMAGE(state, value) {
        state.customTextConsumerFrontPreview = value;
    },

    UPDATE_CUSTOM_TEXT_BACK_PREVIEW_IMAGE(state, value) {
        state.customTextConsumerBackPreview = value;
    }
}