
import axios from 'axios';

export default async ({ app, store }) => {
    if (process.client) {
      if(app.$config.enableTranslation) {
        if(localStorage.getItem('udm.lang') === null) {
          try {
            const apiUrl = process.env.API_DOMAIN
            const response = await axios.post(`${apiUrl}/api/get-lang`)
            
            const apiLocale = response.data.lang
            app.i18n.defaultLocale = apiLocale
            app.i18n.locale = apiLocale
        
            store.commit('translation/UPDATE_LANG', apiLocale)
          } catch (error) {
            console.error('Error fetching language:', error)
          }
        } else {
          const localLang = localStorage.getItem('udm.lang')
          app.i18n.locale = localLang
          store.commit('translation/UPDATE_LANG', localLang)
        }
      } else {
        app.i18n.locale = 'en'
      }
    }
  }