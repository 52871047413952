
export default {
    name: "AdminNav",
    methods: {
        async logoutUser() {
            await this.$auth.logout();
            await this.$store.commit("shopify/RESET_STEPS");
            await window.location.reload();
        }
    }
};
