
	import { googleAnalytics } from "@/mixins/google/googleAnalytics";
    import { locale } from "@/mixins/translation/locale";

    export default {
		mixins: [googleAnalytics, locale],
        name: "SubdomainLayout",
		data() {
			return {
				page: false
			}
		},
		mounted() {
			setTimeout(() => this.page = true, 2000);
		}
    }
