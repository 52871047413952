import { render, staticRenderFns } from "./super-nav.vue?vue&type=template&id=6db82c30"
import script from "./super-nav.vue?vue&type=script&lang=js"
export * from "./super-nav.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLogoBlack: require('/usr/src/eventum-portal/components/icon/logo-black.vue').default,IconClose: require('/usr/src/eventum-portal/components/icon/IconClose.vue').default,IconDashboard: require('/usr/src/eventum-portal/components/icon/dashboard.vue').default,IconCalender: require('/usr/src/eventum-portal/components/icon/calender.vue').default,IconBasket: require('/usr/src/eventum-portal/components/icon/IconBasket.vue').default,IconUser: require('/usr/src/eventum-portal/components/icon/user.vue').default,IconTshirtMenu: require('/usr/src/eventum-portal/components/icon/tshirt-menu.vue').default,IconSettings: require('/usr/src/eventum-portal/components/icon/IconSettings.vue').default,UserIcon: require('/usr/src/eventum-portal/components/user/user-icon.vue').default,IconLogout: require('/usr/src/eventum-portal/components/icon/IconLogout.vue').default})
