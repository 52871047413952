import { render, staticRenderFns } from "./header.vue?vue&type=template&id=4b9ae2e0"
import script from "./header.vue?vue&type=script&lang=js"
export * from "./header.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLogoBlack: require('/usr/src/eventum-portal/components/icon/logo-black.vue').default,IconMenu: require('/usr/src/eventum-portal/components/icon/menu.vue').default})
