
import { formProps } from "@/mixins/form/formProps";

export default {
    mixins: [formProps],
    name: "FormInput",
    props: {
        className: {
            default: "form-input",
            type: String
        },
    }
}
