/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const state = () => ({
    status: ["Active", "Draft", "On hold"],
    types: ["Bulk", "Promo", "Products", "Store"],
    regions: ["UK", "EU"],
    shopifyRegions: ["US"],
    shippingMethods: ["Standard", "Standard tracked", "Express"],
    type: "",
    region: "",
    hasFulfiller: "",
    hasClientCreationAttemptPending: "",
    currency: "",
    vatRate: 0.0,
    notVatRegistered: false,
    temporaryStore: {}
});

export const mutations = {
    ADD_TEMP_STORE(state, value) {
        state.temporaryStore = value;
    },

    UPDATE_EVENT_NAME(state, value) {
        state.name = value;
    },

    UPDATE_LOGO_URL(state, value) {
        state.logo = value;
    },

    UPDATE_EVENT_TYPE(state, value) {
        state.type = value;
    },

    UPDATE_EVENT_REGION(state, value) {
        state.region = value;
    },

    UPDATE_EVENT_CURRENCY(state, value) {
        state.currency = value;
    },
    UPDATE_EVENT_VAT_RATE(state, value) {
        state.vatRate = value;
    },
    UPDATE_NOT_VAT_REGISTERED(state, value) {
        state.notVatRegistered = value;
    },
}