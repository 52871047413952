
    /**
     * @author Jason Curren
     * @description
     * ============================================================================================
     */
    import { googleAnalytics } from "@/mixins/google/googleAnalytics";
    import { locale } from "@/mixins/translation/locale";

    export default {
        mixins: [googleAnalytics, locale],
        name: "HomeLayout",
        props: {
            notRequired: {
                default: () => ([
                    "/event-builder",
                    "/get-started",
                ]),
                type: Array
            },
            shopify: {
                default: () => ([
                    // "/shopify/spoke",
                    // "/shopify/tsas"
                ]),
                type: Array
            }
        },
        watch: {
            $route() {
                if (window.innerWidth < 1024) this.$store.commit("toggle/SET_TOGGLE", "");
            }
        }
    }
