
    export default {
        name: "ProductImageConsumerStoreAndCart",
        props: {
            className: {
                default: "w-full",
                type: String
            },
            product: {
                default: () => ({
                    products: {}
                }),
                type: Object
            },
            productID: {
                default: "",
                type: String
            }
        },
        computed: {
            imageInCartAndCheckout() {
                // IMAGES IN CART AND CHECKOUT
                let imageURL = "";

                if (this.product.products.images?.front_preview) {
                    imageURL = `${this.product.products.images.front_preview}`;
                } else if (this.product.products.images?.back_preview) {
                    imageURL = `${this.product.products.images.back_preview}`;
                }

                return imageURL;
            },
            hasObjectsOnBackDesign() {
                return Boolean(this.product.products.back && this.product.products.back.length);
            },
            hasObjectsOnFrontDesign() {
                return Boolean(this.product.products.front && this.product.products.front.length);
            },
            hasFrontColourPreview() {
                let output = [];

                const { artwork } = this.product.products;

                if (artwork && artwork.front_colours && artwork.front_colours.length) {
                    output = artwork.front_colours;
                }

                return output;
            },
            imageInStoreFrontGallery() {
                // IMAGES IN STORE FRONT GALLERY
                let imageURL = "";

                if (this.product.products.front?.length && this.product.products.artwork?.front_preview) {
                    imageURL = `${this.$config.s3Bucket}/${this.$config.environment}/${this.product.products.artwork.front_preview}`
                } else if (this.product.products.back?.length && this.product.products.artwork?.back_preview) {
                    imageURL = `${this.$config.s3Bucket}/${this.$config.environment}/${this.product.products.artwork.back_preview}`
                } else {
                    imageURL = "";
                    console.log("Product has no design or preview image.");
                }

                return imageURL;
            },
            backPreviewImage() {
                // BACK IMAGES IN BOTH STORE GALLERY AND CART
                let imageURL = "";

                if (this.hasObjectsOnFrontDesign &&
                    this.hasObjectsOnBackDesign &&
                    this.product.products.images?.back_preview
                ) {
                    imageURL = `${this.product.products.images.back_preview}`;
                } else if (this.hasObjectsOnFrontDesign && 
                    this.hasObjectsOnBackDesign && 
                    this.product.products.artwork?.back_preview
                ) {
                    imageURL = `${this.$config.s3Bucket}/${this.$config.environment}/${this.product.products.artwork.back_preview}`
                }

                return imageURL;
            }
        }
    }
