export default function ({ redirect, route }) {
    //shopify is dead
    return redirect("/get-started");
    // const shop = route.query.shop;
    // const appendShopText = shop ? `shop=${route.query.shop}` : "";
    // if (route.fullPath.includes("spoke")) {
    //     return redirect("/shopify/signup?region=US&" + appendShopText);
    // } else {
    //     return redirect("/shopify/signup?" + appendShopText);
    // }
}