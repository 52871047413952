/**
 * @description
 * ================================================================================================
 */

export const state = () => ({
    action: [
        "Event created",
        "Event deleted",
        "Event restored",
        "Event locked",
        "Event unlocked",
        "Event status updated",
        "Event product added",
        "Event product deleted",
        "Event file added",
        "Event file deleted",
        "Product added",
        "Product deleted",
        "File added",
        "File deleted",
        "Order added",
        "Order sent to fulfiller",
        "Fulfiller registration success",
        "Fulfiller registration failure",
        "Fulfiller submission success",
        "Fulfiller submission failure",
        "Credit account requested",
        "New tenant requires verification",
        "Tenant credit account activated",
        "Tenant credit account discount amount updated",
        "Tenant credit account shipping discount amount updated"
    ],
});