import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

export default (app, inject) => {
    try {
        const echo = new Echo({
            authEndpoint: app.$config.endpoint + "/api/broadcasting/auth",
            broadcaster: app.$config.websocketBroadcaster,
            key: app.$config.websocketKey,
            wsHost: app.$config.websocketHost,
            wsPort: app.$config.websocketPort,
            forceTLS: app.$config.websocketEncrypt,
            disableStats: true,
            cluster: app.$config.websocketCluster,
            authorizer: (channel, options) => {
                return {
                    authorize: (socketId, callback) => {
                        app.$axios
                            .post("/api/broadcasting/auth", {
                                socket_id: socketId,
                                channel_name: channel.name,
                            })
                            .then((response) => {
                                callback(false, response.data);
                            })
                            .catch((error) => {
                                callback(true, error);
                            });
                    },
                };
            },
        });

        inject("echo", echo);
    } catch (e) {
        console.log(e);
    }
};
