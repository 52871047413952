export const state = () => ({
    countries: [],
    states: [],
});

export const mutations = {
    UPDATE_COUNTRIES(state, value) {
        state.countries = value;
    },

    UPDATE_STATES(state, value) {
        state.states = value;
    },
}