
    export default {
        name: "IconAdd",
        props: {
            fill: {
                default: "#000",
                type: String
            }
        }
    }
