import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10919ed4 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _b6a7801c = () => interopDefault(import('../pages/assets/index.vue' /* webpackChunkName: "pages/assets/index" */))
const _d3c4bd60 = () => interopDefault(import('../pages/audit-logs/index.vue' /* webpackChunkName: "pages/audit-logs/index" */))
const _6acd07c9 = () => interopDefault(import('../pages/client-revenue/index.vue' /* webpackChunkName: "pages/client-revenue/index" */))
const _6170f88f = () => interopDefault(import('../pages/clients/index.vue' /* webpackChunkName: "pages/clients/index" */))
const _d7e02726 = () => interopDefault(import('../pages/custom-solutions/index.vue' /* webpackChunkName: "pages/custom-solutions/index" */))
const _500d731b = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _5e3384ef = () => interopDefault(import('../pages/event-builder/index.vue' /* webpackChunkName: "pages/event-builder/index" */))
const _41002a08 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _83d280d6 = () => interopDefault(import('../pages/fulfillers/index.vue' /* webpackChunkName: "pages/fulfillers/index" */))
const _599501de = () => interopDefault(import('../pages/get-started/index.vue' /* webpackChunkName: "pages/get-started/index" */))
const _42070dab = () => interopDefault(import('../pages/how-does-it-work/index.vue' /* webpackChunkName: "pages/how-does-it-work/index" */))
const _177a47b0 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _01b487ac = () => interopDefault(import('../pages/need-merch-for-an-event/index.vue' /* webpackChunkName: "pages/need-merch-for-an-event/index" */))
const _5568dcd4 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _69efc498 = () => interopDefault(import('../pages/our-products/index.vue' /* webpackChunkName: "pages/our-products/index" */))
const _57511af4 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _30fb3884 = () => interopDefault(import('../pages/privacy-policy-de/index.vue' /* webpackChunkName: "pages/privacy-policy-de/index" */))
const _f2fa7b6e = () => interopDefault(import('../pages/privacy-policy-fr/index.vue' /* webpackChunkName: "pages/privacy-policy-fr/index" */))
const _46b3bcbb = () => interopDefault(import('../pages/privacy-policy-nl/index.vue' /* webpackChunkName: "pages/privacy-policy-nl/index" */))
const _05e18393 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _4fbc4e48 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _f48568dc = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _a1a8df64 = () => interopDefault(import('../pages/shopify-widget-instructions/index.vue' /* webpackChunkName: "pages/shopify-widget-instructions/index" */))
const _32f411a3 = () => interopDefault(import('../pages/terms-and-conditions/index.vue' /* webpackChunkName: "pages/terms-and-conditions/index" */))
const _3b8a3910 = () => interopDefault(import('../pages/terms-and-conditions-buyer-us/index.vue' /* webpackChunkName: "pages/terms-and-conditions-buyer-us/index" */))
const _b9ef5544 = () => interopDefault(import('../pages/terms-and-conditions-us/index.vue' /* webpackChunkName: "pages/terms-and-conditions-us/index" */))
const _18586b8f = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _815d395c = () => interopDefault(import('../pages/clients/create/index.vue' /* webpackChunkName: "pages/clients/create/index" */))
const _43e747b9 = () => interopDefault(import('../pages/events/create/index.vue' /* webpackChunkName: "pages/events/create/index" */))
const _7e52868a = () => interopDefault(import('../pages/help/frequently-asked-questions/index.vue' /* webpackChunkName: "pages/help/frequently-asked-questions/index" */))
const _373c056a = () => interopDefault(import('../pages/help/help-articles/index.vue' /* webpackChunkName: "pages/help/help-articles/index" */))
const _2097710a = () => interopDefault(import('../pages/help/shipping-and-delivery/index.vue' /* webpackChunkName: "pages/help/shipping-and-delivery/index" */))
const _0cb5fc1c = () => interopDefault(import('../pages/reset-password/confirmation/index.vue' /* webpackChunkName: "pages/reset-password/confirmation/index" */))
const _d634e144 = () => interopDefault(import('../pages/settings/billing-account/index.vue' /* webpackChunkName: "pages/settings/billing-account/index" */))
const _127e0296 = () => interopDefault(import('../pages/settings/company-details/index.vue' /* webpackChunkName: "pages/settings/company-details/index" */))
const _528b0510 = () => interopDefault(import('../pages/settings/shopify-connection/index.vue' /* webpackChunkName: "pages/settings/shopify-connection/index" */))
const _1c98f9f0 = () => interopDefault(import('../pages/shopify/signup/index.vue' /* webpackChunkName: "pages/shopify/signup/index" */))
const _72d0796c = () => interopDefault(import('../pages/shopify/spoke/index.vue' /* webpackChunkName: "pages/shopify/spoke/index" */))
const _40fbe06e = () => interopDefault(import('../pages/shopify/tsas/index.vue' /* webpackChunkName: "pages/shopify/tsas/index" */))
const _249adf5c = () => interopDefault(import('../pages/users/create/index.vue' /* webpackChunkName: "pages/users/create/index" */))
const _7b05c3d6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _842e6df8 = () => interopDefault(import('../pages/email-verification/_token/index.vue' /* webpackChunkName: "pages/email-verification/_token/index" */))
const _1707017a = () => interopDefault(import('../pages/events/_slug/index.vue' /* webpackChunkName: "pages/events/_slug/index" */))
const _261ef1f7 = () => interopDefault(import('../pages/reset-password/_token/index.vue' /* webpackChunkName: "pages/reset-password/_token/index" */))
const _4306bc6c = () => interopDefault(import('../pages/site-preview/_slug/index.vue' /* webpackChunkName: "pages/site-preview/_slug/index" */))
const _7f96e5dd = () => interopDefault(import('../pages/events/_slug/catalogue/index.vue' /* webpackChunkName: "pages/events/_slug/catalogue/index" */))
const _374ba7c0 = () => interopDefault(import('../pages/events/_slug/fulfillers/register/index.vue' /* webpackChunkName: "pages/events/_slug/fulfillers/register/index" */))
const _016f0691 = () => interopDefault(import('../pages/_slug/index.vue' /* webpackChunkName: "pages/_slug/index" */))
const _35db6b82 = () => interopDefault(import('../pages/_slug/contact-us/index.vue' /* webpackChunkName: "pages/_slug/contact-us/index" */))
const _4dec9b31 = () => interopDefault(import('../pages/_slug/faqs/index.vue' /* webpackChunkName: "pages/_slug/faqs/index" */))
const _59a49d04 = () => interopDefault(import('../pages/_slug/payment-success/index.vue' /* webpackChunkName: "pages/_slug/payment-success/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _10919ed4,
    name: "about"
  }, {
    path: "/assets",
    component: _b6a7801c,
    name: "assets"
  }, {
    path: "/audit-logs",
    component: _d3c4bd60,
    name: "audit-logs"
  }, {
    path: "/client-revenue",
    component: _6acd07c9,
    name: "client-revenue"
  }, {
    path: "/clients",
    component: _6170f88f,
    name: "clients"
  }, {
    path: "/custom-solutions",
    component: _d7e02726,
    name: "custom-solutions"
  }, {
    path: "/dashboard",
    component: _500d731b,
    name: "dashboard"
  }, {
    path: "/event-builder",
    component: _5e3384ef,
    name: "event-builder"
  }, {
    path: "/events",
    component: _41002a08,
    name: "events"
  }, {
    path: "/fulfillers",
    component: _83d280d6,
    name: "fulfillers"
  }, {
    path: "/get-started",
    component: _599501de,
    name: "get-started"
  }, {
    path: "/how-does-it-work",
    component: _42070dab,
    name: "how-does-it-work"
  }, {
    path: "/login",
    component: _177a47b0,
    name: "login"
  }, {
    path: "/need-merch-for-an-event",
    component: _01b487ac,
    name: "need-merch-for-an-event"
  }, {
    path: "/orders",
    component: _5568dcd4,
    name: "orders"
  }, {
    path: "/our-products",
    component: _69efc498,
    name: "our-products"
  }, {
    path: "/privacy-policy",
    component: _57511af4,
    name: "privacy-policy"
  }, {
    path: "/privacy-policy-de",
    component: _30fb3884,
    name: "privacy-policy-de"
  }, {
    path: "/privacy-policy-fr",
    component: _f2fa7b6e,
    name: "privacy-policy-fr"
  }, {
    path: "/privacy-policy-nl",
    component: _46b3bcbb,
    name: "privacy-policy-nl"
  }, {
    path: "/products",
    component: _05e18393,
    name: "products"
  }, {
    path: "/reset-password",
    component: _4fbc4e48,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _f48568dc,
    name: "settings"
  }, {
    path: "/shopify-widget-instructions",
    component: _a1a8df64,
    name: "shopify-widget-instructions"
  }, {
    path: "/terms-and-conditions",
    component: _32f411a3,
    name: "terms-and-conditions"
  }, {
    path: "/terms-and-conditions-buyer-us",
    component: _3b8a3910,
    name: "terms-and-conditions-buyer-us"
  }, {
    path: "/terms-and-conditions-us",
    component: _b9ef5544,
    name: "terms-and-conditions-us"
  }, {
    path: "/users",
    component: _18586b8f,
    name: "users"
  }, {
    path: "/clients/create",
    component: _815d395c,
    name: "clients-create"
  }, {
    path: "/events/create",
    component: _43e747b9,
    name: "events-create"
  }, {
    path: "/help/frequently-asked-questions",
    component: _7e52868a,
    name: "help-frequently-asked-questions"
  }, {
    path: "/help/help-articles",
    component: _373c056a,
    name: "help-help-articles"
  }, {
    path: "/help/shipping-and-delivery",
    component: _2097710a,
    name: "help-shipping-and-delivery"
  }, {
    path: "/reset-password/confirmation",
    component: _0cb5fc1c,
    name: "reset-password-confirmation"
  }, {
    path: "/settings/billing-account",
    component: _d634e144,
    name: "settings-billing-account"
  }, {
    path: "/settings/company-details",
    component: _127e0296,
    name: "settings-company-details"
  }, {
    path: "/settings/shopify-connection",
    component: _528b0510,
    name: "settings-shopify-connection"
  }, {
    path: "/shopify/signup",
    component: _1c98f9f0,
    name: "shopify-signup"
  }, {
    path: "/shopify/spoke",
    component: _72d0796c,
    name: "shopify-spoke"
  }, {
    path: "/shopify/tsas",
    component: _40fbe06e,
    name: "shopify-tsas"
  }, {
    path: "/users/create",
    component: _249adf5c,
    name: "users-create"
  }, {
    path: "/",
    component: _7b05c3d6,
    name: "index"
  }, {
    path: "/email-verification/:token",
    component: _842e6df8,
    name: "email-verification-token"
  }, {
    path: "/events/:slug",
    component: _1707017a,
    name: "events-slug"
  }, {
    path: "/reset-password/:token",
    component: _261ef1f7,
    name: "reset-password-token"
  }, {
    path: "/site-preview/:slug",
    component: _4306bc6c,
    name: "site-preview-slug"
  }, {
    path: "/events/:slug/catalogue",
    component: _7f96e5dd,
    name: "events-slug-catalogue"
  }, {
    path: "/events/:slug/fulfillers/register",
    component: _374ba7c0,
    name: "events-slug-fulfillers-register"
  }, {
    path: "/:slug",
    component: _016f0691,
    name: "slug"
  }, {
    path: "/:slug/contact-us",
    component: _35db6b82,
    name: "slug-contact-us"
  }, {
    path: "/:slug/faqs",
    component: _4dec9b31,
    name: "slug-faqs"
  }, {
    path: "/:slug/payment-success",
    component: _59a49d04,
    name: "slug-payment-success"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
