/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

import Vue from "vue";
import { mapState } from "vuex";

Vue.mixin({
    computed: {
        ...mapState({
            user: state => state.auth.user,
            logged_in: state => state.auth.loggedIn,
            role_keys: state => Object.keys(state.roles.roles),
            role_values: state => Object.values(state.roles.roles)
        }),
        auth_route() {
            let output = "/api";

            if (this.user && this.user.tenant_id) {
                output += `/${this.user.tenant_id}${this.$route.path}`;
            }

            return output;
        },
        auth_tenant_route() {
            let output = "/api";

            if (this.user && this.user.tenant_id) {
                output += `/${this.user.tenant_id}`;
            }

            return output;
        },
        is_superadmin() {
            let output = false;

            if (this.logged_in && this.user.superadmin && this.user.superadmin === 1) {
                output = true;
            }

            return output;
        }
    },
    methods: {
        has_role(roles = "") {
            let output = false;

            if (this.logged_in && this.user.role) {
                roles = roles.split("|");

                if (roles.indexOf(this.user.role) > -1) output = true;
            }

            return output;
        }
    }
});