/**
 * @author Jason Curren
 * @description inject fabric to the app, global scope as $fabric
 * ================================================================================================
 */

import { fabric } from "fabric";

export default ((app, inject) => {
    inject("fabric", fabric);
});