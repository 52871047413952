/**
 * @description Global store for language
 * ================================================================================================
 */

export const state = () => ({
    lang: null,
});

export const mutations = {
    UPDATE_LANG(state, value) {
        state.lang = value;
        if (process.client) {
            localStorage.setItem('udm.lang', value);
          }
    }
}