
    /**
     * @author Jason Curren
     * @description
     * ============================================================================================
     */
    import { googleAnalytics } from "@/mixins/google/googleAnalytics";

    export default {
        mixins: [googleAnalytics],
        name: "LoginLayout"
    }
