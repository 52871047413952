
    import '~/assets/layout/authentication.scss';
    import { googleAnalytics } from "@/mixins/google/googleAnalytics";

    export default {
  props: {
  },
        mixins: [googleAnalytics],
        name: "AuthenticationLayout"
    }
