import { render, staticRenderFns } from "./header.vue?vue&type=template&id=6319031d"
import script from "./header.vue?vue&type=script&lang=js"
export * from "./header.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLogoLarge: require('/usr/src/eventum-portal/components/icon/logo-large.vue').default,TranslationSite: require('/usr/src/eventum-portal/components/translation/site.vue').default,IconMenu: require('/usr/src/eventum-portal/components/icon/menu.vue').default,SiteAuth: require('/usr/src/eventum-portal/components/site/auth.vue').default})
