/**
 * @author Jason Curren
 * @description used for globally calling csrf tokens on open frontend routes
 * @required send function name for return
 * ================================================================================================
 */

import Vue from "vue";

Vue.mixin({
    methods: {
        get_csrf_token(func) {
            return this.$axios.get(`/api/sanctum/csrf-cookie`, {
            }).then(res => {
                if(func)
                    this[func]();
            }).catch(err => {
                console.log(err);
                throw(err);
            });
        },
        set_csrf_token() {
            this.$axios.get(`/api/sanctum/csrf-cookie`, {
            }).then(res => {
                //
            }).catch(err => {
                console.log(err);
            });
        }
    }
});