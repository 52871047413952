
    export default {
        name: "SiteAuth",
        methods: {
            resetShopifyCheck() {
                this.$store.commit("shopify/RESET_STEPS");

                window.location.href = "/dashboard";
            }
        }
    }
