
    /**
     * @author Jason Curren
     * @description
     * ============================================================================================
     */
    export default {
        name: "ErrorLayout",
        layout: "login",
        props: {
            components: {
                default: () => ({
                    401: "auth", 403: "forbidden", 404: "not", 500: "server"
                }),
                type: Object
            },
            error: {
                default: () => ({}),
                type: Object
            }
        }
    }
