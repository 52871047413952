
import moment from 'moment';
export default {
    name: "ProductDeliveryDate",
    data() {
        return {
            estimatedDeliveryDateX: '',
            estimatedDeliveryDateY: ''
        };
    },
    created() {
        const startDate = moment();

        this.estimatedDeliveryDateX = this.addBusinessDaysToDate(startDate, 4)
            .format('DD-MM-YYYY')
        this.estimatedDeliveryDateY = this.addBusinessDaysToDate(startDate, 6)
            .format('DD-MM-YYYY');
    },
    methods: {
        addBusinessDaysToDate(date, businessDays) {
            // Ensure that the input is a moment instance, cloning it to avoid mutation
            const momentDate = date instanceof moment ? date.clone() : moment(date);

            // Validate that businessDays is a positive integer
            if (!Number.isSafeInteger(businessDays) || businessDays <= 0) {
                // If the input is invalid, return the original moment instance
                return momentDate;
            } else {
                // Calculate the number of calendar days to add for full weeks
                const calendarDaysToAdd = Math.floor(businessDays / 5) * 7;
                momentDate.add(calendarDaysToAdd, "days");

                // Calculate the remaining business days after full weeks are added
                const remainingDays = businessDays % 5;

                // Adjust the date if it falls on a weekend
                const dayOfWeekNumber = momentDate.day();
                if (dayOfWeekNumber === 6) {
                    // If it's Saturday, subtract one day to move to Friday
                    momentDate.subtract(1, "days");
                } else if (dayOfWeekNumber === 0) {
                    // If it's Sunday, subtract two days to move to Friday
                    momentDate.subtract(2, "days");
                }

                // Handle any remaining business days
                if ((momentDate.day() + remainingDays) > 5) {
                    // If adding the remaining days crosses into a weekend,
                    // add two extra calendar days to skip the weekend
                    return momentDate.add(remainingDays + 2, "days");
                } else {
                    // Otherwise, just add the remaining days
                    return momentDate.add(remainingDays, "days");
                }
            }
        }
    }
};
