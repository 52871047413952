export const state = () => ({
    enabled: ""
});

export const mutations = {
    TOGGLE_COOKIES(state, value) {
        state.enabled = value;

        if (value === "accepted" && this.$config.environment === "production") {
            const googleScript = document.createElement("script");

            googleScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-6FW8WMF4MT");

            document.head.appendChild(googleScript);

            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            gtag("js", new Date());
            gtag("config", "G-6FW8WMF4MT");
        }
    }
};