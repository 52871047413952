/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */
export const state = () => ({
    tenant: ""
});

export const mutations = {
    UPDATE_TENANT(state, value) {
        state.tenant = value;
    },
}