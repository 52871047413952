
    import { formCheck } from "@/mixins/form/formCheck";
    import ProductImageConsumerStoreAndCart from "@/components/display/ProductImageConsumerStoreAndCart";

    export default {
        mixins: [formCheck],
        name: "FormConsumer",
        components: {
            ProductImageConsumerStoreAndCart
        },
        props: {
            basket: {
                default: () => ([]),
                type: Array
            },
            regionDefault: {
                default: "",
                type: String,
            },
            componentFormData: {
                default: () => ({
                    address_first_line: "",
                    address_second_line: "",
                    address_city: "",
                    address_county: "",
                    address_postcode: "",
                    address_country: "",
                    address_state: "",
                    email: "",
                    name: "",
                    phone: ""
                }),
                type: Object
            },
            componentFormIgnore: {
                default: () => (["message", "address_second_line", "address_county", 'address_state']),
                type: Array
            },
            invalidAddress: {
                default: false,
                type: Boolean
            },
            invalidPromoCode: {
                default: false,
                type: Boolean
            },
            apiFormErrors: {
                default: () => ([]),
                type: Array
            },
            label: {
                default: 'Order your gift',
                type: String,
            },
            title: {
                default: "events.claim_your_free_gift",
                type: String
            }
        },
        data() {
            return {
                lockedToCountry: [],
                loading: false,
                states: this.$store.state.countries.states
            }
        },
        mounted() {
            this.$axios.get(`api/${this.$store.state.tenant.tenant}${this.$route.path}/locked-to-country`, {
                params: { lang: this.$i18n.locale }
            }).then(res => {
                this.lockedToCountry = res.data;
            }).catch(err => {
                console.log(err);
            }).finally(() => {})
        },
        computed: {
            countries() {
                if (this.lockedToCountry.length > 0) {
                    return this.lockedToCountry;
                } else {
                    return this.$store.state.countries.countries;
                }
            },
            basketItems() {
                if (this.$store.state.basket.products.length > 0) {
                    return this.$store.state.basket.products;
                }
                return this.basket;
            },
            totalPrice() {
                let output = 0;

                this.$store.state.basket.products.forEach(data => {
                    const price = this.productPrice(data.price,data.store_default_vat);
                    output += parseInt(data.quantity) * price;
                });

                return output.toFixed(2);
            },
            currentApiFormErrors() {
                const errors = {};

                for (const [key, [errorMessage]] of this.apiFormErrors) {
                    errors[key] = errorMessage;
                }

                return errors;
            },
            errorMessages() {
                const messages = {};

                for (const key in this.formErrors) {
                    messages[key] = this.formErrors[key];
                }

                for (const key in this.currentApiFormErrors) {
                    messages[key] = this.currentApiFormErrors[key];
                }

                return messages;
            },
            region() {
                if (this.regionDefault) {
                    return this.regionDefault === 'UK' ? 'United Kingdom' : 'Netherlands';
                }
                else {
                    return this.$store.state.event.region === 'UK' ? 'United Kingdom' : 'Netherlands';
                }
            },
            isUSConsumer() {
                return this.formData['address_country'] === 'United States of America';
            }
        },
        methods: {
            productVat(price, store_default_vat) {
                if(this.$store.state.event.notVatRegistered) {
                    return false;
                }
                if(store_default_vat !== 1) {
                    return false
                }
                let output = 0;
                output = price;
                const vat = (output * this.$store.state.event.vatRate / 100);
                return (parseFloat(vat)).toFixed(2);
            },
            productPrice(price, store_default_vat) {
                let output = price;
                if (this.$store.state.event.notVatRegistered) {
                    return output;
                }
                if (store_default_vat !== 1) {
                    return output;
                }
                const vat = (output * this.$store.state.event.vatRate / 100);
                return (parseFloat(output) + parseFloat(vat)).toFixed(2);
            },
            checkIfPromo() {
                this.$store.state.event.type === "promo" ? this.checkIfCode() : this.submitForm();
            },
            checkIfCode() {
                this.formData.promo_code ? this.submitForm() : this.formErrors.push("promo_code");
            },
            hasError(key) {
                return this.formErrors.includes(key) || this.currentApiFormErrors.hasOwnProperty(key);
            },
            getColourMatch({ selected, store }) {
                let output = store.identifier.split("_")[1];

                if (selected.colour_names && selected.colour_names) {
                    const position = selected.colours.indexOf(store.colour);

                    if (position > -1 && selected.colour_names[position]) {
                        output = selected.colour_names[position];

                        output = output.replace(/_/g, " ");
                    }
                }

                output = output.charAt(0).toUpperCase() + output.slice(1);

                return output;
            },
            getErrorMessages(key) {
                return this.errorMessages[key] || this.formMessage;
            },
            postForm() {
                this.checkIfPromo();
            },
            submitForm() {
                this.$emit("post-form", { ...this.formData, basket: this.basket });
            },
            updateForm(val) {
                if (val === undefined) val = '';

                this.formData[val.key] = val.value;
            },
        }
    }
