
    import { locale } from "@/mixins/translation/locale";

    export default {
		mixins: [locale],
        name: "TranslationSelect",
        props: {
            tabindex: {
                type: Number,
                required: false,
                default: 0,
            }
        },
        data() {
            return {
                open: false,
            };
        },
        methods: {
            setLanguage(lang) {
                this.changeLocale(lang);
                this.open = false;
            }
        }
    };
