
    export default {
        name: "SiteCookies",
        methods: {
            storeCookies(value) {
                localStorage.setItem("site-cookies", value);

                this.$store.commit("cookies/TOGGLE_COOKIES", value);
            }
        }
    }
