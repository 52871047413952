/**
 * @description
 * ================================================================================================
 */

export const state = () => ({
    dropdownOptions: [
        "Name A-Z",
        "Name Z-A",
        "Price High-Low",
        "Price Low-High",
        "Recently Added"
    ],
});