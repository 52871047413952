/**
 * @description
 * ================================================================================================
 */

export const state = () => ({
    status: [
        "new",
        "queued downloading",
        "downloaded",
        "queued processing",
        "processing",
        "processed",
        "queued submitting to fulfiller",
        "submitting to fulfiller",
        "submitted to fulfiller",
        "picked",
        "in production",
        "awaiting packing",
        "packaged",
        "ready to ship",
        "shipped",
        "address issue",
        "artwork issue",
        "awaiting reprint",
        "cancelled",
        "fulfiller submission error",
        "fulfiller routing error",
        "over budget",
        "over budget limit"
    ],
});
