import moment from "moment";

export const orderCheckout = {
    methods: {
        addBusinessDaysToDate(date, businessDays) {

            // Ensure that the input is a moment instance, cloning it to avoid mutation
            const momentDate = date instanceof moment ? date.clone() : moment(date);

            // Validate that businessDays is a positive integer
            if (!Number.isSafeInteger(businessDays) || businessDays <= 0) {
                // If the input is invalid, return the original moment instance
                return momentDate;
            } else {
                // Calculate the number of calendar days to add for full weeks
                const calendarDaysToAdd = Math.floor(businessDays / 5) * 7;
                momentDate.add(calendarDaysToAdd, "days");

                // Calculate the remaining business days after full weeks are added
                const remainingDays = businessDays % 5;

                // Adjust the date if it falls on a weekend
                const dayOfWeekNumber = momentDate.day();
                if (dayOfWeekNumber === 6) {
                    // If it's Saturday, subtract one day to move to Friday
                    momentDate.subtract(1, "days");
                } else if (dayOfWeekNumber === 0) {
                    // If it's Sunday, subtract two days to move to Friday
                    momentDate.subtract(2, "days");
                }

                // Handle any remaining business days
                if ((momentDate.day() + remainingDays) > 5) {
                    // If adding the remaining days crosses into a weekend,
                    // add two extra calendar days to skip the weekend
                    return momentDate.add(remainingDays + 2, "days");
                } else {
                    // Otherwise, just add the remaining days
                    return momentDate.add(remainingDays, "days");
                }
            }
        },
        productPrice(price, store_default_vat) {
            let output = price;
            if (this.$store.state.event.notVatRegistered) {
                return output;
            }
            if (store_default_vat !== 1) {
                return output;
            }
            const vat = (output * this.$store.state.event.vatRate / 100);
            return (parseFloat(output) + parseFloat(vat)).toFixed(2);
        },
        getColourMatch({ selected, store }) {
            let output = store.identifier.split("_")[1];

            if (selected.colour_names && selected.colour_names) {
                const position = selected.colours.indexOf(store.colour);

                if (position > -1 && selected.colour_names[position]) {
                    output = selected.colour_names[position];

                    output = output.replace(/_/g, " ");
                }
            }

            output = output.charAt(0).toUpperCase() + output.slice(1);

            return output;
        },
        productVat(price, store_default_vat) {
            if (this.$store.state.event.notVatRegistered) {
                return false;
            }
            if (store_default_vat !== 1) {
                return false
            }

            let output = 0;
            output = price;
            const vat = (output * this.$store.state.event.vatRate / 100);
            return (parseFloat(vat)).toFixed(2);
        },
        appendNestedDataToFormData(formData, data, parentKey = '') {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key];
                    const formKey = parentKey ? `${parentKey}[${key}]` : key;

                    if (value instanceof Blob) {
                        // Append Blob objects (e.g., files, images)
                        formData.append(formKey, value, value.name || 'blob');
                    } else if (Array.isArray(value)) {
                        // Handle arrays, including empty ones
                        if (value.length === 0) {
                            // Append an empty array by sending the key without any value
                            formData.append(formKey, '');
                        } else {
                            // Append each element of the array
                            value.forEach((item, index) => {
                                if (typeof item === 'object' && item !== null) {
                                    // Recursively process nested objects or arrays
                                    this.appendNestedDataToFormData(formData, item, `${formKey}[${index}]`);
                                } else {
                                    // Append simple types (string, number, etc.)
                                    formData.append(`${formKey}[${index}]`, item);
                                }
                            });
                        }
                    } else if (typeof value === 'object' && value !== null) {
                        // Recursively process nested objects
                        this.appendNestedDataToFormData(formData, value, formKey);
                    } else {
                        // Append simple types (string, number, null, etc.)
                        formData.append(formKey, value);
                    }
                }
            }
        },
    },
};