/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

import Vue from "vue";
import { mapState } from "vuex";

Vue.mixin({
    computed: {
        ...mapState({
            lang: state => state.translation.lang
        })
    }
});