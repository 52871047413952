
    export default {
        name: "IconExclamation",
        props: {
            fill: {
                default: "#DC2626",
                type: String
            }
        }
    }
