/**
 * @author Jason Curren
 * @description Global store for purchases
 * ================================================================================================
 */

import { get, set } from 'idb-keyval';
export const state = () => ({
    active: false,
    change: [],
    products: [],
    basketName: '',
    show: false
});

export const mutations = {
    ADD_PRODUCT(state, value) {
        state.products.push(value);
        set(`${state.basketName}basket-contents`, state.products);
    },

    ADD_QUANTITY(state, value) {
        if (state.products[value].quantity) {
            state.products[value].quantity++;
        }
        set(`${state.basketName}basket-contents`, state.products);
    },

    REMOVE_PRODUCT(state, value) {
        state.products.splice(value, 1);
        set(`${state.basketName}basket-contents`, state.products);
    },

    REMOVE_QUANTITY(state, value) {
        if (state.products[value].quantity) {
            state.products[value].quantity--;
        }
        set(`${state.basketName}basket-contents`, state.products);
    },

    CLEAR_PRODUCTS(state) {
        state.products = [];
        set(`${state.basketName}basket-contents`, state.products);
    },

    OPEN_CLOSE_BASKET(state) {
        state.show = !state.show;
    },

    SET_BASKET(state) {
        state.active = !state.active;
    },

    SET_PRODUCTS(state, value) {
        state.products = value;
    },

    UPDATE_CHANGE(state, value) {
        state.change = value;
    },

    UPDATE_BASKET_NAME(state, value) {
        state.basketName = value.replace(/\s+/g, "-");
    }
}

export const actions = {
    fetchBasketData({ commit, state }) {
        get(`${state.basketName}basket-contents`).then((val) => val ? commit('SET_PRODUCTS', val) : []);
    },
    convertBase64ImagesToBlobs({ commit, state }) {
        function base64ToBlob(base64, mimeType = 'image/png') {
            // Method: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
            // Remove the Base64 prefix if it exists.
            const base64Data = base64.split(',')[1] || base64;
            
            // Decode the Base64 string into a binary string.
            const byteCharacters = atob(base64Data);
            
            // Create an array to hold the byte data.
            const byteNumbers = new Array(byteCharacters.length);
            
            // Convert each character to a byte number.
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            
            // Convert the byte numbers array to a Uint8Array.
            const byteArray = new Uint8Array(byteNumbers);
            
            // Create a Blob with the given mime type.
            return new Blob([byteArray], { type: mimeType });
        }

        // Create a new array of updated products.
        const updatedProducts = state.products.map((product) => {
            // Make a deep copy of the product object, otherwise replacing won't work.
            const updatedProduct = JSON.parse(JSON.stringify(product));

            if (updatedProduct.products?.images) {
                Object.keys(updatedProduct.products.images).forEach((key) => {
                    const image = updatedProduct.products.images[key];

                    // Check if the image string is in base64 format (others are S3 links).
                    if (typeof image === 'string' && image.startsWith('data:image/')) {
                        // Convert and replace the base64 with a Blob.
                        const contentType = image.substring(image.indexOf(':') + 1, image.indexOf(';'));
                        const blob = base64ToBlob(image, contentType);
                        updatedProduct.products.images[key] = blob;
                    }
                });
            }

            return updatedProduct;
        });
        commit('SET_PRODUCTS', updatedProducts);
    },
    async convertBlobsToBase64Images({ commit, state }) {
        // Helper function to convert a Blob to Base64.
        async function blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }
    
        // Recursive deep copy function that preserves Blob objects.
        async function deepCopyWithBlobConversion(obj) {
            if (obj instanceof Blob) {
                // Convert Blob to Base64.
                return await blobToBase64(obj);
            } else if (Array.isArray(obj)) {
                // Recursively process each item in the array.
                return await Promise.all(obj.map(item => deepCopyWithBlobConversion(item)));
            } else if (typeof obj === 'object' && obj !== null) {
                // Recursively copy each key in the object.
                const copy = {};
                for (const key in obj) {
                    copy[key] = await deepCopyWithBlobConversion(obj[key]);
                }
                return copy;
            }
            // Return non-object values as is.
            return obj;
        }
    
        // Deep copy and process each product.
        const updatedProducts = await Promise.all(
            state.products.map(product => deepCopyWithBlobConversion(product))
        );
    
        // Commit the updated products array to the store.
        commit('SET_PRODUCTS', updatedProducts);
    }
};