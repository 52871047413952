import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";

export default ((app, inject) => {
    Vue.use(VueToast);

    inject("toast", VueToast);
});

Vue.mixin({
    methods: {
        // handle response and display appropriate toast
        // todo currently success and error are styled the same but this may change in future
        // ========================================================================================
        showToastNotification(res) {
            if (res && res.data.message) {
                const message = res.data.message;

                if (res.data.success === true) {
                     this.$toast.success(message);
                }

                if (res.data.success === false) {
                     this.$toast.error(message);
                }
            }
        },
    }
});