
    export default {
        name: "IconStores",
        props: {
            fill: {
                default: "#fff",
                type: String
            }
        }
    }
