const middleware = {}

middleware['event'] = require('../middleware/event.js')
middleware['event'] = middleware['event'].default || middleware['event']

middleware['redirectToSignup'] = require('../middleware/redirectToSignup.js')
middleware['redirectToSignup'] = middleware['redirectToSignup'].default || middleware['redirectToSignup']

middleware['shopify'] = require('../middleware/shopify.js')
middleware['shopify'] = middleware['shopify'].default || middleware['shopify']

export default middleware
