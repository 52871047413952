/**
 * @author Jason Curren
 * @description Reusable props for form components to communicate to parent
 * ================================================================================================
 */
export const formProps = {
    props: {
        default: {
            default: "",
            type: [Number, String, Boolean],
        },
        // We want to watch this and set the value of input to this whenever it changes
        defaultValue: {
            default: "",
            type: [Number, String, Boolean],
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        error: {
            default: false,
            type: Boolean,
        },
        label: {
            default: "",
            type: String,
        },
        message: {
            default: "",
            type: String,
        },
        max: {
            default: 0,
            type: Number,
        },
        min: {
            default: 0,
            type: Number,
        },
        maxlength: {
            default: 5000,
            type: Number,
        },
        height: {
            default: "10rem",
            type: String,
        },
        options: {
            default: () => [],
            type: Array,
        },
        placeholder: {
            default: "",
            type: String,
        },
        target: {
            default: "",
            type: String,
        },
        type: {
            default: "text",
            type: String,
        },
        postInputLabel: {
            type: String,
        },
        preInputLabel: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        preInputIcon: {
            // for showing icon inside input box before placeholder text
            type: Object,
        },
        postInputIcon: {
            // for showing icon inside input box just before input box ends
            type: Object,
        },
    },
    data() {
        return {
            input: null,
        };
    },
    mounted() {
        if (this.default || this.default === 0) this.input = this.default;
    },
    watch: {
        input() {
            this.$emit("update-form", { key: this.target, value: this.input });
        },
        defaultValue() {
            this.input = this.defaultValue;
        },
    },
};