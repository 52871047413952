import { render, staticRenderFns } from "./checkout.vue?vue&type=template&id=0fc949ce"
import script from "./checkout.vue?vue&type=script&lang=js"
export * from "./checkout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconClose: require('/usr/src/eventum-portal/components/icon/IconClose.vue').default,IconLoader: require('/usr/src/eventum-portal/components/icon/IconLoader.vue').default,FormConsumer: require('/usr/src/eventum-portal/components/form/consumer.vue').default,PaymentIncludingAddress: require('/usr/src/eventum-portal/components/payment/IncludingAddress.vue').default,ProductDeliveryDate: require('/usr/src/eventum-portal/components/product/ProductDeliveryDate.vue').default})
