export const state = () => ({
    paymentRegion: "US",
    stepsCompleted: false,
    steps: [
        { complete: true, step: "1", title: "banner_steps.sign_up" },
        { complete: false, step: "2", title: "banner_steps.install_shopify_app" },
        { complete: false, step: "3", title: "banner_steps.become_our_customer" },
        { complete: false, step: "4", title: "banner_steps.connect_your_payment_method" }
    ],
    registrationRoute: "",
    shopifyUrl: ""
});

export const mutations = {
    RESET_STEPS(state) {
        state.stepsCompleted = false;

        state.steps.forEach((data, index) => {
            if (index) data.complete = false;
        });
    },

    UPDATE_PAYMENT_REGION(state, value) {
        state.paymentRegion = value;
    },

    UPDATE_REGISTRATION_ROUTE(state, value) {
        state.registrationRoute = value;
    },

    UPDATE_STEPS_COMPLETED(state, value) {
        state.stepsCompleted = value;
    },

    UPDATE_STEPS(state, { index, value }) {
        state.steps[index].complete = value;

        let complete = 0;

        state.steps.forEach(data => {
            if (data.complete) {
                complete++;

                if (complete === state.steps.length) state.stepsCompleted = true;
            }
        });
    },

    UPDATE_SHOPIFY_URL(state, value) {
        state.shopifyUrl = value;
    }
}