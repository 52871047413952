
    /**
     * @author Jason Curren
     * @description
     * ============================================================================================
     */
    export default {
        name: "SiteHeader",
        props: {
            shopify: {
                type: Boolean,
            },
        }
    }
