import { render, staticRenderFns } from "./home.vue?vue&type=template&id=ab6337ea"
import script from "./home.vue?vue&type=script&lang=js"
export * from "./home.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconLoader: require('/usr/src/eventum-portal/components/icon/IconLoader.vue').default,SiteHeader: require('/usr/src/eventum-portal/components/site/header.vue').default,SiteCookies: require('/usr/src/eventum-portal/components/site/cookies.vue').default})
