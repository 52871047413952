export const locale = {
    data() {
        return {
            loading: false
        }
    },
    computed: {
        selected() {
            let selected = this.$i18n.locales.filter((lang) => {
                return this.$i18n.locale === lang.code;
            });
            return selected ? selected[0] : null;
        }
    },
    methods: {
        async changeLocale(lang) {
            if(this.logged_in) {
                await this.setLocale(lang);
            } else {
                await this.updateLanguage(lang);
            }
        },
        async getLocale() {
            this.loading = true;
            
            this.$axios.post(`/api/get-lang`).
            then(async res => {
                await this.updateLanguage(res.data.lang);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
        },
        async setLocale(lang) {
            this.loading = true;

            this.$axios.post(`/api/set-lang`, {lang: lang})
            .then(res => {
                this.updateLanguage(res.data.lang);
            }).catch(err => {
                console.log(err);
            }).finally(() => {
                this.loading = false;
            });
        },
        async updateLanguage(lang) {
            if(this.lang !== lang) {
                await this.$store.commit("translation/UPDATE_LANG", lang);
                await this.$i18n.setLocale(this.lang);
            }
        }
    },
};
