/**
 * @author Jason Curren
 * @description check for user logged in shopify events, to know if they are all set up or not
 * ================================================================================================
 */

export default function ({ $auth, app, error, store, route, redirect }) {
    if (
        $auth.loggedIn &&
        $auth.user &&
        $auth.user.shopify &&
        !store.state.shopify.stepsCompleted
    ) {
        app.$axios
            .get(`/api/${$auth.user.tenant_id}/events/shopify-event`, {
                shopify: true,
            })
            .then(async (res) => {
                if (res.data) checkShopifyUrl(res.data);
            })
            .catch((err) => {
                error(err);
            });

        const checkShopifyUrl = (data) => {
            //shopify is dead
            return redirect("/get-started");
            // const status = data.status === "connected" ? true : false;
            //
            // const link =
            //     !status && data.shopify_installation_link
            //         ? data.shopify_installation_link
            //         : "";
            // if (route.fullPath.includes("check-installation") && !link) {
            //     return redirect("/shopify/signup");
            // }
            //
            // store.commit("shopify/UPDATE_STEPS", { index: 1, value: status });
            // store.commit("shopify/UPDATE_SHOPIFY_URL", link);
            //
            // checkFulfiller(data);
        };

        const checkFulfiller = (data) => {
            const fulfiller = data.hasFulfiller ? true : false;

            const link = !fulfiller
                ? `/api/${$auth.user.tenant_id}/events/${data.slug}/fulfillers/register`
                : "";

            store.commit("shopify/UPDATE_REGISTRATION_ROUTE", link);
            store.commit("shopify/UPDATE_STEPS", {
                index: 2,
                value: fulfiller,
            });

            checkPaymentMethod(data);
        };

        const checkPaymentMethod = (data) => {
            const payment = data.hasDefaultPaymentMethod ? true : false;
            const region = data.region || "US";

            store.commit("shopify/UPDATE_PAYMENT_REGION", region);
            store.commit("shopify/UPDATE_STEPS", { index: 3, value: payment });
        };
    } else {
        store.commit("shopify/UPDATE_STEPS_COMPLETED", true);
    }
}