/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const state = () => ({
    id: "",
    location: ""
});

export const mutations = {
    SET_FILE(state, value) {
        state.location = value;
    },

    SET_FILE_ID(state, value) {
        state.id = value;
    }
}