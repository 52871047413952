/**
* =================================================================================================
*/
import Vue from "vue";
import * as day from "dayjs";

const customParseFormat = require("dayjs/plugin/customParseFormat");

day.extend(customParseFormat);

// Price formats
// ================================================================================================
Vue.filter("formatPriceDecimal", (val, region, style = "currency") => {
    val = parseFloat(val).toFixed(2);

    const regions = {
        "EU": {
            currency: "EUR",
            locale: "nl-NL"
        },
        "UK": {
            currency: "GBP",
            locale: "en-GB"
        },
        "US": {
            currency: "USD",
            locale: "en-US"
        }
    };

    return val.toLocaleString(regions[region].locale, {
        style, currency: regions[region].currency, maximumFractionDigits: 2
    });
});

// Date formats
// ================================================================================================
Vue.filter("formatHumanDate", val => day(val).format("D MMMM YYYY"));

// Format keys
// ================================================================================================
Vue.filter("formatKey", val => val.split(/(?=[A-Z])/).join(" ").toLowerCase());

// Format database strings
// ================================================================================================
Vue.filter("formatDB", val => val.replace(/_/g, " "));