
    /**
     * @author Jason Curren
     * @description
     * ============================================================================================
     */
    import { googleAnalytics } from "@/mixins/google/googleAnalytics";
    import { locale } from "@/mixins/translation/locale";

    export default {
        mixins: [googleAnalytics, locale],
        name: "DefaultLayout",
        mounted() {
            if (this.$config.environment !== "dev" && !this.is_superadmin) this.addChatBot();

            this.checkTempStore();
        },
        methods: {
            addChatBot() {
                const script = document.createElement("script");

                script.src = "//eu.fw-cdn.com/12442428/503013.js";
                script.chat = "true";

                document.head.appendChild(script);

                if (this.$auth.user) setTimeout(() => this.addChatUser(), 1000);
            },
            addChatUser() {
                if (window.fcWidget && window.fcWidget.user) {
                    window.fcWidget.user.setFirstName(this.$auth.user.name);
                    window.fcWidget.user.setEmail(this.$auth.user.email);
                } else if (this.$auth.user) {
                    setTimeout(() => this.addChatUser(), 1000);
                }
            },
            checkTempStore() {
                if (this.$store.state.event.temporaryStore.img_id) {
                    this.loading = true;

                    this.$axios.post(`${this.auth_tenant_route}/assets/move`, {
                        img_id: this.$store.state.event.temporaryStore.img_id
                    }).then(res => {
                        const imgId = res.data.data;

                        this.createStore(imgId, this.$store.state.event.temporaryStore);
                    }).catch(err => {
                        console.log(err);

                        this.loading = false;
                    });
                }
            },
            createStore(image_id, event) {
                const { page, products, region, theme, type } = event;

                this.$axios.post(`${this.auth_tenant_route}/events/create`, {
                    image_id, page, products, region, theme, type
                }).then(res => {
                    this.$store.commit("event/ADD_TEMP_STORE", {});

                    this.$router.push(`/events/${res.data.data.slug}`);

                    this.loading = false;
                }).catch(err => {
                    console.log(err);

                    this.loading = false;
                });
            }
        },
        watch: {
            $route() {
                if (window.innerWidth < 1024) this.$store.commit("toggle/SET_TOGGLE", "");
            }
        }
    }
