/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const state = () => ({
    menu: [
        { title: "Our Products", id: 'our-products', url: "/our-products" },
        { title: "Need merch for an event?", id: 'need-merch-for-an-event?', url: "/need-merch-for-an-event" },
        { title: "How does it work?", id: 'how-does-it-work?', url: "/how-does-it-work" },
        // { title: "Custom solutions", url: "/custom-solutions" }
    ],
});