/**
 * @author Jason Curren
 * @description
 * ================================================================================================
 */

export const state = () => ({
    guide: true,
    modal: "",
    type: ""
});

export const mutations = {
    SET_TOGGLE(state, value) {
        state.type = value;
    },

    SET_GUIDE(state) {
        state.guide = !state.guide;
    },

    SET_TOGGLER(state, obj) {
        state[obj.target] = obj.value;

        if (state.modal) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }
    },
};