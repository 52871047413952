import Vue from "vue";

import { Editor, EditorContent } from "@tiptap/vue-2";

import StarterKit from "@tiptap/starter-kit";

import "prosemirror-view/style/prosemirror.css";

Vue.mixin({
    components: {
        EditorContent
    }
});

export default ((app, inject) => {
    inject("tiptapEditor", Editor);
    inject("tiptapStarterkit", StarterKit);
});